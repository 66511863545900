import styled from 'styled-components';

export const AdventureWrapper = styled.div`
`;

export const PhotographListWrapper = styled.div`
  padding: 80px 80px 50px;
    min-height: 100vh;
   @media(max-width: 1048px) {
    padding: 50px 20px;
  }
  @media(max-width: 500px) {
    padding: 100px 20px;
  }
`;

export const PhotographListTitle = styled.p`
  text-transform: uppercase;
  font-family: 'Allerta Stencil', sans-serif;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 10px;
`

export const PhotographListSubtitle = styled.p`
  font-size: 1rem;
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 10px;
`