import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const ButtonContainer = styled.div`
  margin: 5px;
  ${({ height }) => `height: ${height}`};
  ${({ width }) => `width: ${width}`};
  margin: 0 10px;
  padding: 20px 0;
  cursor: ${({ disable }) => disable ? 'default' : 'pointer'};
  transition: .3s;
  border: 2px solid;
  border-radius: 4px;
  border-color: ${({ disable }) => (disable ? '#bbb' : '#FF6F00')};
  ${({ disable }) => disable && `background-color: #ddd`};
  &:hover {
    ${({ disable }) => !disable && `background-color:#fff`};
  }
`;
export const ButtonMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4;
  height: 100%;
`
export const ButtonText = styled.span`
  font-weight: bold;
  color: ${({ isInvert, color }) => (isInvert ? color : '#FF6F00')};
  ${({ disable }) => disable && `border: 1px solid red;`};
  letter-spacing: 0.8px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.2px;
`;
export const ButtonImageContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`
export const ButtonImage = styled.image`
  width: 100%;
  height: 100%;
`