import React from 'react';
import { Link } from 'react-router-dom'

import ytIcon from './resources/yt_icon.png';
import instaIcon from './resources/insta_icon.png';
import ytIconWhite from './resources/yt_icon_white.png';
import instaIconWhite from './resources/insta_icon_white.png';
import csIcon from './resources/cs_logo.png';
import csIconWhite from './resources/cs_logo_white.png';
import linkedIn from './resources/linkedIn.png';

import {
  SocialNetworksWrapper,
  SocialNetworksList,
  SocialNetworksListItem,
  SocialNetworksIcon,
  SocialNetworksIconWrapper
} from './SocialNetworks.style'

const SocialNetworks = ({ isLight }) => {

  return (
    <SocialNetworksWrapper>
      <SocialNetworksList>
        {/* <SocialNetworksListItem
          title='Couchsurfing'
          isLight={isLight}  
        >
          <a 
            href='https://www.couchsurfing.com/people/rodrigomunoztravel'
            target='_blank'
            rel='noreferrer noopener'
          >
            <SocialNetworksIcon 
              src={isLight ? csIconWhite : csIcon}
              alt='Couchsurfing icon'
            />
          </a>
        </SocialNetworksListItem> */}
        {/* <SocialNetworksListItem
          title='LinkedIn'
          isLight={isLight}  
        >
          <a 
            href='https://www.linkedin.com/in/empty-road-project/'
            target='_blank'
            rel='noreferrer noopener'
          >
            <SocialNetworksIcon
              src={linkedIn}
              alt='LinkedIn icon'
              style={{ width: '13px', height: '100%', marginBottom: '1px' }}
            />
          </a>
        </SocialNetworksListItem> */}
        <SocialNetworksListItem
          title='Instagram'
          isLight={isLight}  
        >
          <a 
            href='https://www.instagram.com/emptyroad_project'
            target='_blank'
            rel='noreferrer noopener'
          >
            <SocialNetworksIcon 
              src={isLight ? instaIconWhite : instaIcon}
              alt='Instagram icon'
            />
          </a>
        </SocialNetworksListItem>
        <SocialNetworksListItem
          title='Youtube'
          isLight={isLight}  
        >
          <a
            href='https://www.youtube.com/@emptyRoadProject'
            target='_blank'
            rel='noreferrer noopener'
          >
            <SocialNetworksIcon 
              src={isLight ? ytIconWhite : ytIcon}
              alt='Youtube icon'
            />
          </a>
        </SocialNetworksListItem>
      </SocialNetworksList>
    </SocialNetworksWrapper>
  )
}

export default SocialNetworks;