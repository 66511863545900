import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next';

import Navbar from './components/navbar/Navbar'
import NavMobile from './components/navMobile/NavMobile';
import NewsletterModal from './components/NewsletterModal/NewsletterModal';

import Home from './pages/home/Home'
import Adventures from './pages/storyBlog/StoryBlogList/StoryBlogList'
import About from './pages/about/About'
import LeadMagnet from './pages/LeadMagnet/LeadMagnet'
import AdventuresView from './pages/storyBlog/StoryBlogView/StoryBlogView'
import Photography from './pages/photoBlog/PhotoBlogList/PhotoBlogList'
import PhotographyView from './pages/photoBlog/PhotoBlogView/PhotoBlogView'
import Footer from './components/footer/Footer'
import CreatePost from './pages/blogManager/CreatePost/CreatePost'
import EditPost from './pages/blogManager/EditPost/EditPost'
import BlogManager from './pages/blogManager/BlogManager/BlogManager'
import CloudManagerList from './pages/blogManager/CloudManagerList/CloudManagerList'
import CloudManagerView from './pages/blogManager/CloudManagerView/CloudManagerView'
import NewsletterConfirm from './pages/NewsletterConfirm/NewsletterConfirm'
import NewsletterUnsubscribe from './pages/NewsletterUnsubscribe/NewsletterUnsubscribe'
import NewsletterList from './pages/blogManager/NewsletterList/NewsletterList'
import SellLetterLaCarta from './pages/SellLetterLaCarta/SellLetterLaCarta'
import Login from './pages/login/login'
import BeyondTravel from './pages/beyondTravel/BeyondTravel'
import Architecture from './pages/architecture/Architecture'
import Hospitality from './pages/hospitality/Hospitality'
import erpLogo from './assests/images/logo-erp.png';

import {
  AppWrapper,
  InfoBar,
  InfoBarText,
  InfoBarButton,
  AppTop,
  InfoBarButtonIcon,
  AppLayoutOthersWrapper,
  NewsletterButton,
  LogoImage
} from './App.styles'

const PrivateRoute = ({ component: Component, ...props }) => {
  const token = localStorage.getItem('token')
  return (
    <Route 
      {...props}
      render={() => token 
        ? <Component /> 
        : <Redirect to='/' />
      }
    />
  )
}

const App = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const elRef = useRef();
  const [isScrollTop, setIsScrollTop] = useState(false)
  const [isOpenNewsletterModal, setIsOpenNewsletterModal] = useState(false)
  const [zenMode, setZenMode] = useState(false)

  const { pathname } = location 

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    
    let lang = 'es'
    
    if (userLanguage.includes('en')) {
      lang = 'en'
    } else if (userLanguage.includes('fr')) {
      lang = 'fr'
    } else if (userLanguage.includes('pl')) {
      lang = 'pl'
    } else if (userLanguage.includes('pt')) {
      lang = 'pt'
    }

    i18n.changeLanguage(lang)
  
  }, [])

  const isSpecialRoute = pathname.includes('newsletter') || pathname.includes('admin') || pathname.includes('login')
  const isLeadMagnetRoute = pathname.includes('la-pregunta') // Lead magnet page
  const isJoinCartaRoute = pathname.includes('join-la-carta') // Sell letter page
  
  const className = 'modal-open'

  useEffect(() => {
    active || isOpenNewsletterModal
      ? document.body.classList.add(className)
      : document.body.classList.remove(className);
  }, [active, isOpenNewsletterModal])


  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onScroll = (evt) => {
        const width = window.innerWidth
        const hasClass = document.querySelector(`.${className}`);
        if (width > 1000 && hasClass) {
          setActive(false)
          setIsOpenNewsletterModal(false)
          document.body.classList.remove(className);
        }
      };

      window.addEventListener("resize", onScroll);
      return () => window.removeEventListener("resize", onScroll);
    }
  }, [])
  
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onScroll = () => {
        const { scrollY } = window;
        setIsScrollTop(scrollY < 100)
      };

      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [])
  
  return (
    <>
      {isSpecialRoute ? (
        <>
          <AppLayoutOthersWrapper>
            <Switch>
              <Route exact path='/admin/login' component={Login} />
              <Route exact path='/newsletter/confirm' component={NewsletterConfirm} />
              <Route exact path='/newsletter/unsubscribe' component={NewsletterUnsubscribe} />
              <PrivateRoute exact path='/admin' component={BlogManager} />
              <PrivateRoute path='/admin/edit/:id' component={EditPost} />
              <PrivateRoute path='/admin/create' component={CreatePost} />
              <PrivateRoute exact path='/admin/cloud' component={CloudManagerList} />
              <PrivateRoute path='/admin/cloud/:folder' component={CloudManagerView} />
              <PrivateRoute path='/admin/newsletter' component={NewsletterList} />
            </Switch>
          </AppLayoutOthersWrapper>
        </>
      ) : (
      <>
        <AppWrapper ref={elRef}>
          {(!isLeadMagnetRoute && !zenMode && !isJoinCartaRoute) && (
            <AppTop>
              {/* <InfoBar onClick={() => setIsOpenNewsletterModal(true)}>
                <InfoBarText>{t('infoNewsletter')}</InfoBarText>
                <InfoBarButton>Abrir <InfoBarButtonIcon>&gt;</InfoBarButtonIcon></InfoBarButton>
              </InfoBar> */}
              <Navbar
                isActive={active}
                setActive={setActive}
                isNavbarBackground={!isScrollTop}
              />
              {/* <NavMobile
                isActive={active}
                setActive={setActive}
              /> */}
            </AppTop>
          )}
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/adventures' component={Adventures} />
            {<Route exact path='/adventures/:slug' render={
              () => <AdventuresView zenMode={zenMode} setZenMode={setZenMode}  isScrollTop={isScrollTop}/>
            }/>}
            <Route exact path='/photography' component={Photography} />
            {<Route exact path='/photography/:slug' render={
              () => <PhotographyView zenMode={zenMode} setZenMode={setZenMode} isScrollTop={isScrollTop} />
            }/>}
            <Route exact path='/mas-alla-del-viaje' component={BeyondTravel} />
            <Route exact path='/mas-alla-del-viaje/architecture' component={Architecture} />
            <Route exact path='/mas-alla-del-viaje/hospitality' component={Hospitality} />
            <Route exact path='/beyond-travel' component={BeyondTravel} />
            {/* <Route exact path='/about' component={About} /> */}
            <Route exact path='/la-pregunta' component={LeadMagnet} />
            <Route exact path='/join-la-carta' component={SellLetterLaCarta} />
            <Redirect to='/' />
          </Switch>
          {!isLeadMagnetRoute && !isJoinCartaRoute && <Footer />} 
        </AppWrapper>
        <NewsletterModal
          isOpen={isOpenNewsletterModal}
          onClose={() => setIsOpenNewsletterModal(false)}
        />
        {/* {!isLeadMagnetRoute && !isJoinCartaRoute &&
          <NewsletterButton onClick={() => setIsOpenNewsletterModal(true)}>
            <LogoImage src={erpLogo} alt='Logo Empty Road Project' />
          </NewsletterButton>
        }
         */}
      </>
      )}
    </>
  );
};

export default App;