import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SocialNetworks from '../socialNetworks/SocialNetworks';
import menuStructure from '../../utils/menuStructure';
import SectionBox from '../sectionBox/SectionBox';
import erpLogo from '../../assests/images/logo-erp.png';

import {
  FooterWrapper,
  FooterFragment,
  FooterMenuLeft,
  FooterMenuRight,
  FooterMenuList,
  FooterListItem,
  FooterBlog,
  FooterBlogBox,
  FooterInfo,
  FooterSupport,
  FooterSupportText,
  BoldText,
  FooterInfoWrapper,
  FooterSocialNetworksWrapper,
  FooterBrandName,
  FooterMainWrapper,
  FooterMain,
  FooterPolicyWrapper,
  LogoImage
} from './Footer.styles.js';

import wave from './camino-5.jpg';

const Footer = () => {

  const { t } = useTranslation()

  const renderMenuList = useMemo(() => (
    menuStructure.map(
      ({ title, path }) => (
        <FooterListItem>
          <Link to={path}>
            {t(title)}
          </Link>
        </FooterListItem>
      )
    )
  ), [])

  return (
    <FooterWrapper>
      <SectionBox imageUrl={wave} />
      <FooterFragment >
         <FooterBlog>
          <Link to='/admin/login'>
            <FooterBlogBox />
          </Link> 
        </FooterBlog>
      </FooterFragment>

      <FooterMainWrapper>
        <FooterMain>
          <FooterMenuLeft>
            <LogoImage src={erpLogo} alt='Logo Empty Road Project' />
            <FooterBrandName translate='no'>Empty Road Project</FooterBrandName>
            {/* <FooterMenuList>
              {renderMenuList}
            </FooterMenuList> */}
          </FooterMenuLeft>
          
          {/* <FooterMenuLeft>
            <FooterInfo>Empty Road App</FooterInfo>
          </FooterMenuLeft> */}
          
          <FooterMenuRight>
            <FooterSocialNetworksWrapper>
              <SocialNetworks />
            </FooterSocialNetworksWrapper>

            {/* <FooterSupport>
              <FooterSupportText>Puedes apoyar <BoldText>Empty Road Project</BoldText> con la cantidad que decidas</FooterSupportText>
              <SocialNetworks />
              paypal
              patreon fotos
              btc
            </FooterSupport> */}
          </FooterMenuRight>
        
        </FooterMain>
        <FooterInfoWrapper>
            <FooterInfo>{t('footer.allImages')}</FooterInfo>
          </FooterInfoWrapper> 
        <FooterPolicyWrapper>
          <Link to='/privacy-policy'>
            {t('footer.privacy')}
          </Link>
          <FooterInfo>-</FooterInfo>
          <Link to='/legal-policy'>
            {t('footer.legal')}
          </Link>
          <FooterInfo>-</FooterInfo>
          <Link to='/cookies-policy'>
            {t('footer.cookies')}
          </Link>
          </FooterPolicyWrapper>
      </FooterMainWrapper>
    </FooterWrapper>
  );
};

export default Footer;