import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import AdventureArticule from '../adventureArticule/AdventureArticule';
import LoadingAdventureArticule from '../LoadingAdventureArticule/LoadingAdventureArticule';
import apiUrl from '../../../utils/apiUrl'
import {
  AdventuresListWrapper,
  AdventuresListContent,
  AdventuresListItem
} from './AdventureList.styles'

const AdventureList = ({ limit, isBiggerGrid }) => {
  const [adventures, setAdventures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { i18n } = useTranslation();
  const lang = i18n.language

  useEffect(() => {
    setIsLoading(true)
    let isCurrent = true

    fetch(`${apiUrl}/api/web/blog?lang=${lang}`)
      .then(res => res.json())
      .then(({ status, data }) => {
        if (status !== 'OK') {
          console.error('Error getting post list')
        }
         if (limit) {
          const dataSlice = data.slice(0, limit)
          isCurrent && setAdventures(dataSlice)
        } else {
          isCurrent && setAdventures(data)
        }
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
    return ()=> isCurrent = false
  }, [])


  const loadingItems = (
    <AdventuresListContent>
        <AdventuresListItem>
          <LoadingAdventureArticule />
        </AdventuresListItem>
        <AdventuresListItem>
          <LoadingAdventureArticule />
        </AdventuresListItem>
        <AdventuresListItem>
          <LoadingAdventureArticule />
        </AdventuresListItem>
    </AdventuresListContent>
  )

  const renderAdventureList = () => {  
    return (
      <AdventuresListContent isBiggerGrid={isBiggerGrid}>
        {
          adventures.map(adventure => (
            <AdventuresListItem key={adventure._id} isBiggerGrid={isBiggerGrid}>
              <AdventureArticule
                adventure={adventure}
                disabled={adventure.visibility !== 'public'}
              />
            </AdventuresListItem>
          ))
        }
      </AdventuresListContent>
    )
  }

  return (
    <AdventuresListWrapper>
      {
        isLoading || !adventures.length
          ? loadingItems
          : renderAdventureList()
      }
    </AdventuresListWrapper>
  );
};

export default AdventureList;