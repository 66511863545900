import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next';


import Header from '../../components/header/Header';
import AdventureList from '../../components/adventures/adventureList/AdventureList';
import PhotographyList from '../../components/photography/photographyList/PhotographyList';
import SectionBox from '../../components/sectionBox/SectionBox';
import BannerApp from "../../components/bannerApp/bannerApp";
import Button from "../../components/button/Button";
import NewsletterBox from "../../components/NewsletterBox/NewsletterBox";
import NewsletterHeaderBox from "../../components/NewsletterHeaderBox/NewsletterHeaderBox";
import { blogName, photo } from "../../constants";
import road from "./road.jpg";
import carousel from "./carousel.jpg";

import {
  HomeBrandName,
  HomeWrapper,
  HomeSectionHeader,
  HomeBoxTitle,
  HomeBoxPreTitle,
  HomeBoxSeparator,
  HomeBox,
  HomeBoxPurpose,
  HomeSectionTitle,
  HomeSection,
  HomeSectionSubtitle,
  HomeSectionButtonWrapper,
  Space,
  HeaderClaimWrapper,
  HeaderClaimText,
  HeaderSpecialText,
  HomeBoxSubtitle,
  HomeSectionTitleWrapper,
  HomeCopyBox,
  HomeSpecialText,
  HomeCopyText,
  HomeSpace,
  HomeBoxPurposeBrand,
  HomeCopyBrand,
  HomeBoxTitleBold,
  HomeBoxPurposeDetails,
  HomeBoxPurposeDetailsTravel,
  HomeBoxPurposeDetailsChange,
  HomeBoxPainPointText,
  HomeBoxPainPointTextItalic,
  HomeBoxPainPointTextSpecial,
  HomeBoxPainPointTextWrapper,
  HomeBoxPainPoint,
  NewHeader,
  TextBold,
  Text,
  HomeBoxPurposeDetailsTravelLine,
  HeaderText,
  HeaderLogo,
  HeaderArea,
  HomeImg,
  HomeClaimContainer,
  HomeName,
  Block,
  TextItalic,
  TextHighlight,
  TextPersonal,
  HomeTitle,
  TextLight,
  HomeBlogSection,
  HomeBlogs,
  HomeCarousel,
  TextAllerta,
  TextUnderline,
  TextLink,
  HomeImgContainer
} from './Home.styles'


import emptyRoadLogo from '../../resources/images/emptyRoadLogo.png';
import yt_icon from '../../resources/images/yt_icon_white.png';

const Home = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const monthsArr = [[1, 'Uno'],[2, 'Dos'],[3, 'Tres'],[4, 'Cuatro'],[5, 'Cinco'],[6, 'Seis'],[7, 'Siete'],[8, 'Ocho'],[9, 'Nueve'],[10, 'Diez'],[11, 'Once'],[12, 'Doce'],[13, 'Trece'],[14, 'Catorce'],[15, 'Quince'],[16, 'Dieciséis'],[17, 'Diecisiete'],[18, 'Dieciocho'],[19, 'Diecinueve'],[20, 'Veinte'],[21, 'Veintiuno'],[22, 'Veintidós'],[23, 'Veintitrés'],[24, 'Veinticuatro'],[25, 'Veinticinco'],[26, 'Veintiséis'],[27, 'Veintisiete'],[28, 'Veintiocho'],[29, 'Veintinueve'],[30, 'Treinta'],[31, 'Treinta y uno'],[32, 'Treinta y dos'],[33, 'Treinta y tres'],]

  const startedTravelDate = new Date('2024-08-01');
  const currDate = new Date()
  const elapsedMonths = (currDate.getFullYear() - startedTravelDate.getFullYear()) * 12 + (currDate.getMonth() - startedTravelDate.getMonth());
  const [monthNumInt, monthNumText] = monthsArr[elapsedMonths - 1]


  return (
    <HomeWrapper>
      <Header>
        {/* <HeaderClaimWrapper>
          <HeaderArea>
            <HeaderLogo src={emptyRoadLogo} />
            <HeaderClaimText>
              <HeaderText>
                Una vida,
              </HeaderText> 
              <HeaderSpecialText>
                un VIAJE
              </HeaderSpecialText> 
            </HeaderClaimText>
          </HeaderArea>
        </HeaderClaimWrapper> */}
      <NewHeader>
          <HomeImgContainer><HomeImg src={road} alt='Otro camino' /></HomeImgContainer>
        
        <HomeClaimContainer>
          <HomeName>EMPTY ROAD PROJECT</HomeName>
          <Text>Si buscas <TextHighlight>los 10 mejores destinos</TextHighlight> para el verano</Text>
          <Text><TextPersonal>te has equivocado de web.</TextPersonal></Text>
          <Block/>
          <Block/>
          <Block/>
          <Text>Esto es <TextAllerta>Empty Road Project</TextAllerta>.</Text>
          <Block/>
          <Block/>
          <Block/>
          <Text><TextBold>2 personas.</TextBold></Text>
          <Text>Dos mochilas.</Text>
          <Text><TextItalic>Y miles de kilómetros de tierra, océanos y asfalto.</TextItalic></Text>
          <Block/>
          <Block/>
          <Block/>
          <Block/>
          <Text><TextBold>Para nosotros, este viaje no es una simple aventura, </TextBold></Text>
            <Text><TextBold> es una experiencia <TextItalic>esencial</TextItalic> en la vida.</TextBold></Text>
          <Block/>
          <Block/>
          <Block/>
          <Block/>
          <Text>No es una huida de la realidad,</Text>
          <Text>sino una forma de expandirla.</Text>
          <Text>Ganar perspectiva y enriquecernos de experiencias.</Text>
          <Block/>
          <Block/>
          <Block/>
          <Text><TextBold>Marcar un antes y un después en nuestras vidas.</TextBold></Text>
          <Block/>
          <Block/>
          <Text>Por eso, elegimos el <TextHighlight>Otro Camino</TextHighlight>.</Text>
          <Block/>
          <Block/>
          <Text>Aquel sin señales ni referencias conocidas,</Text>
          <Text>El que da vértigo al inicio,</Text>
          <Text>pero que nos desafía a evolucionar.</Text>
          <Block/>
          <Block/>
          <Text><TextBold>El camino vacío.</TextBold></Text>
          <Block/>
          <Block/>
        </HomeClaimContainer>
      </NewHeader>
      </Header>
      <HomeCarousel src={carousel} alt='Otro camino' />
      <NewsletterBox />
      <HomeBoxPainPoint>
        <HomeTitle color='#fff'>¿Por Qué Ir Por Otros Caminos?</HomeTitle>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'><TextHighlight>Piensa en esto:</TextHighlight></Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'><TextBold>Tienes 89 años.</TextBold></Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'>Es hora de la merienda.</Text>
        <Text color='#fff'>Tus nietos se sientan a tu lado.</Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'>Te miran con curiosidad.</Text>
        <Text color='#fff'><TextItalic>Esperan historias.</TextItalic></Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'>Pero <TextBold>no</TextBold> las tienes.</Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'>Porque...</Text>
        <Text color='#fff'>pasaste la vida en piloto automático.</Text>
        <Text color='#fff'>no era <TextItalic>"el momento"</TextItalic>.</Text>
        <Text color='#fff'>nunca diste el paso.</Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'>Y entonces lo entiendes:</Text>
        <Block/>
        <Block/>
        <Text color='#fff'><TextItalic>"No te arrepientes de lo que hiciste.</TextItalic></Text>
        <Text color='#fff'><TextItalic>Te arrepientes de lo que <TextBold>no</TextBold>."</TextItalic></Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'>Nosotros tomamos <TextHighlight>Otro Camino</TextHighlight>.</Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text color='#fff'><TextBold>Y ahora lo estamos contando.</TextBold></Text>
      </HomeBoxPainPoint>
    
      <HomeBlogSection id="blog">
        <HomeTitle>Blog</HomeTitle>
        <Text><TextItalic>"No hagas fotos, no hagas vídeos, vive el momento."</TextItalic> decía a Karo.</Text>
        <Block/>
        <Block/>
        <TextPersonal>Ahora me arrepiento completamente de ello.</TextPersonal>
        <Block/>
        <Block/>
        <Text>He descubierto una de las cosas más bonitas de viajar...</Text>
        <Block/>
        <Block/>
        <Text><TextBold>Compartir.</TextBold></Text>
        <Block/>
        <Block/>
        <Text>Nuestros padres se quedan más tranquilos,</Text>
        <Text>y la familia y amigos exploran con nosotros.</Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text>Este blog <TextBold>no</TextBold> es para leer con prisas.</Text>
        <Text>Sino para viajar <TextBold>sin moverte del sitio.</TextBold></Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text><TextItalic><TextUnderline>Sugerencia de uso:</TextUnderline></TextItalic></Text>
        <Text><TextItalic>Ponte música y sírvete una copa de vino</TextItalic></Text>
        <Text><TextItalic>o una cerveza artesanal,</TextItalic></Text>
        <Text><TextItalic>a tu gusto.</TextItalic></Text>
        <Text><TextItalic>Incluso, puedes abrir Google Maps (<TextLink href='https://www.google.com/maps' rel='noreferrer' target="_blank">Link</TextLink>)</TextItalic></Text>
        <Text><TextItalic>y seguir la ruta con el dedo.</TextItalic></Text>
        <Block/>
        <Block/>
        <Block/>
        <Block/>
        <Text>Ahora sí. Respira hondo.</Text>
        <Text><TextPersonal>Comienza el viaje.</TextPersonal></Text>
        <HomeBlogs>
          <HomeSection>
            <HomeSectionHeader>
              <HomeSectionTitleWrapper>
                <HomeTitle>{photo}</HomeTitle>
              </HomeSectionTitleWrapper>
              <HomeSectionSubtitle>{t('home.blog.miradaDescription')}</HomeSectionSubtitle>
            </HomeSectionHeader>

            <PhotographyList limit={6} />
            <HomeSectionButtonWrapper>
              <Button 
               isInvert
                width={"300px"}
                onClick={() => history.push('/photography')}
                text={`${t('home.callToAction.goTo')}, ${photo}`}
              />
            </HomeSectionButtonWrapper>
          </HomeSection>

          <HomeSection>
            <HomeSectionHeader>
              <HomeSectionTitleWrapper>
                <HomeTitle>{blogName}</HomeTitle>
              </HomeSectionTitleWrapper>
              <HomeSectionSubtitle>{t('home.blog.aventuraDescription')}</HomeSectionSubtitle>
              {/* <HomeSectionInfo></HomeSectionInfo> */}
            </HomeSectionHeader>
            <AdventureList limit={6} />
            <HomeSectionButtonWrapper>
              <Button 
                isInvert
                width={"300px"}
                onClick={() => history.push('/adventures')}
                text={`${t('home.callToAction.goTo')}, ${blogName}`}
              />
            </HomeSectionButtonWrapper>
          </HomeSection>
        </HomeBlogs>
      </HomeBlogSection>
    </HomeWrapper>
  );
};

export default Home;