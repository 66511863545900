import styled from 'styled-components';

export const NewsletterConfirmWrapper = styled.div`
  margin-top: 100px;
  height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
`
export const LoginInputWrapper = styled.div`
  margin: 20px;
  width: 350px;
  height: 100px;
`

export const LoginInput = styled.input`
  padding: 30px 20px;
  width: 250px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 5px 5px 5px rgba(0,0,0, .2);
  border: 1px solid #333;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  &:focus {
    padding: 27px 17px;
    border: 3px solid orange
  }
`

export const NewsletterConfirmTitle = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 30px;
`
export const NewsletterConfirmText = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0;
`

export const NewsletterConfirmInfoText = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
`

export const NewsletterConfirmCancelButton = styled.button`
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #08f26e;
  border: 2px solid #07da63;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
`
export const NewsletterConfirmSubmitButton = styled.button`
  padding: 10px 20px;
  margin: 10px 0;
  border: 2px solid red;
  color: red;
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
`
export const NewsletterConfirmInfoWrapper = styled.div`
  margin: 30px 0;
`

export const MailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin: 50px 0;
  @media(max-width: 720px) {
    margin: 30px 0;
  }
`
export const MailsItem = styled.div`
   display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
`

export const MailsGoHome = styled.div`
  margin-top: 30px;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
`

export const MailIcon = styled.img`
  width: 35px;
  margin-right: 8px;
  @media(max-width: 720px) {
    width: 25px;
  }
`

export const MailText = styled.span`
  font-size: 16px;
  color:  #FF6F00;
  text-decoration: underline;
  white-space: nowrap;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`
export const MailSuccessInfo = styled.p`
  font-size: 14px;
  font-weight: 500;
@media(max-width: 720px) {
    font-size: 12px;
  }
`

export const MailSuccessTitle = styled.p`
  font-size: 18px;
  @media(max-width: 720px) {
    font-size: 24px;
  }
`