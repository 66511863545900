import styled, { keyframes } from 'styled-components';

export const PhotographyListWrapper = styled.div`
`
export const PhotographyListContent = styled.ul`
  margin: 40px auto;
  max-width: 1500px;
  display: ${({ isBiggerGrid }) => isBiggerGrid ? 'inline-grid' : 'grid'};
  grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  @media(max-width: 1260px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  }
  @media(max-width: 1260px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  }
   @media(max-width: 960px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr' : '1fr 1fr'};
  }
  @media(max-width: 910px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr' : '1fr 1fr'};
  }
  
   @media(max-width: 750px) {
     grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr' : '1fr'};
     max-width: ${({ isBiggerGrid }) => isBiggerGrid ? 'auto' : '200px'};
  }
    
  @media(max-width: 695px) {
   grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr' : '1fr'};
  }
  @media(max-width: 450px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr' : '1fr'};
  }
`


export const PhotographyListItem = styled.li`
  width:  ${({ isBiggerGrid }) => isBiggerGrid ? '200px' : 'auto'};
`

// Keyframe animation for shimmer effect
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const SkeletonLoading = styled.div`
  height: 100px;
  height: 200px;
  border-radius: 20px;
  transition: .3s;
  background: linear-gradient(to right, #f0f0f0 4%, #e0e0e0 25%, #f0f0f0 36%);
  background-size: 1000px 100%;
  animation: ${shimmer} 1.5s infinite;
`