import styled from 'styled-components';

export const SectionBoxWrapper = styled.section`
  position: relative;
  height: 300px;
  background-color: #cdcdcd;
  background-image: ${({ imageUrl }) => imageUrl && `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: ${({ isBlack }) => isBlack ? '#000' : '#fff'};
  text-align: ${({ isLeft }) => isLeft ? 'left' : 'right'};
 @media(max-width: 500px) {
    height: 300px;
  }
`

export const SectionBoxTextWrapper = styled.div`

`
export const SectionBoxText = styled.h3`
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 30px;
`

export const SectionBoxTextItalic = styled.span`
  font-style: italic;
`

export const SectionBoxButton = styled.button`
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 30px;
`