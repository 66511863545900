import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../components/header/Header';
import AdventureList from '../../../components/adventures/adventureList/AdventureList';
import NewsletterBox from "../../../components/NewsletterBox/NewsletterBox";

import market from '../../../assests/images/market.jpeg';
import { blogName } from '../../../constants';

import {
  AdventureWrapper,
  AdventureListWrapper,
  AdventureListTitle,
  AdventureListSubtitle
} from './StoryBlogList.style'

const StoriesBlogList = () => {
  const { t } = useTranslation()
  return (
    <AdventureWrapper>
      <Header imageSrc={market} isLessHeigh />
      <AdventureListWrapper>
        <AdventureListTitle>
          {blogName}
        </AdventureListTitle>
        <AdventureListSubtitle>{t('home.blog.aventuraDescription')}</AdventureListSubtitle>
        <AdventureList isBiggerGrid />
      </AdventureListWrapper>
      <NewsletterBox />
    </AdventureWrapper>
  );
};

export default StoriesBlogList;