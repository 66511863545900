import styled from 'styled-components';

export const AdventuresListWrapper = styled.div`
`

export const AdventuresListContent = styled.ul`
  margin: 40px auto;
  max-width: 1500px;
  display: ${({ isBiggerGrid }) => isBiggerGrid ? 'inline-grid' : 'grid'};
  grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  @media(max-width: 1260px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  }
  @media(max-width: 1260px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  }
   @media(max-width: 960px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr' : '1fr 1fr'};
  }
  @media(max-width: 910px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr' : '1fr 1fr'};
  }
  
   @media(max-width: 750px) {
     grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr 1fr' : '1fr'};
     max-width: ${({ isBiggerGrid }) => isBiggerGrid ? 'auto' : '200px'};
  }
    
  @media(max-width: 695px) {
   grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr 1fr' : '1fr'};
  }
  @media(max-width: 450px) {
    grid-template-columns: ${({ isBiggerGrid }) => isBiggerGrid ? '1fr' : '1fr'};
  }
`


export const AdventuresListItem = styled.li`
  width:  ${({ isBiggerGrid }) => isBiggerGrid ? '200px' : 'auto'};
   max-width: 33,3%;
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  transition: .3s;
  &:hover {
    box-shadow: 0px 10px 10px rgba(0,0,0, .1);
  }
`