import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  AdventureArticuleCountry,
  AdventureArticuleWrapper,
  AdventureArticuleThumbnail,
  AdventureArticuleThumbnailWrapper,
  AdventureArticuleBox,
  AdventureArticuleTitle,
  AdventureArticuleDecorator,
  // AdventureButton,
  AdventureButtonWrapper,
  AdventureText,
  AdventureArticuleText,
  AdventureCallText,
  AdventureCallWrapper
} from './AdventureArticule.styles'

const AdventureArticule = ({ adventure, disabled }) => {
  const {
    _id,
    beginningContent = '',
    meta_title,
    meta_description,
    slug,
    title,
    country,
    excerpt,
    publicationDate,
    author,
    isPublic,
    categories,
    mainImage,
    smallMainImage,
    thumbnail = {},
  } = adventure

  const { t } = useTranslation()

  const { src, alt } = smallMainImage || mainImage

  const post = (
    <AdventureArticuleWrapper disabled={disabled}>
      <AdventureArticuleThumbnailWrapper>
        {
          src && 
            <AdventureArticuleThumbnail
              src={src}
              alt={alt}
            />
        }
      </AdventureArticuleThumbnailWrapper>
      <AdventureArticuleBox>
        <AdventureArticuleCountry>
          {country}
        </AdventureArticuleCountry>
        {/* <AdventureArticuleDecorator /> */}
        <AdventureArticuleTitle>
          {title}
        </AdventureArticuleTitle>
        <AdventureCallWrapper>
          <AdventureCallText>
            {t('card.aventura.goToPost')}
          </AdventureCallText>
          <svg viewBox="0 0 25 25" width={25}>
            <g id="Right-2" data-name="Right">
              <polygon fill="#FF6F00" points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" />
            </g>
          </svg>
        </AdventureCallWrapper>
        {beginningContent && (
          <AdventureArticuleText>
            {beginningContent}
          </AdventureArticuleText>
        )}
      </AdventureArticuleBox>
      <AdventureButtonWrapper>
        <AdventureText>
          {t('card.aventura.readMore')}
        </AdventureText>
      </AdventureButtonWrapper>
    </AdventureArticuleWrapper>
  )
  
  return disabled ? (
    post
  ) : (
    <Link to={`/adventures/${slug}`}>
      {post}
    </Link>
  ) 
};

export default AdventureArticule;