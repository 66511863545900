import styled from 'styled-components';

export const AppWrapper = styled.div`
  position: relative;
  text-align: center;
`
export const AppTop  = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
export const InfoBar = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  width: 100%;
  background: #FF6F00;
  animation: colorAnimation 5s infinite;
  transition: 1s;
  @keyframes colorAnimation {
    0% {
      background: #FF6F00;
    }
    50% {
      background: orange;
    }
    100% {
      background: #FF6F00;
    }
  }
  &:hover {
    opacity: .8;
  }
`
export const InfoBarText = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  text-align: center;
  font-weight: bold;
  margin-right: 10px;
  color: #fff;
  font-size: 12px;
`
export const InfoBarButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center; 
  background-color: #fff;
  padding: 0 5px 0 7px;
  border: none;
  font-size: 8px;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;

`
export const InfoBarButtonIcon = styled.span`
  margin-left: 4px;
  font-size: 9px;
  font-weight: 600;
`

export const AppLayoutOthersWrapper = styled.div`
  margin: 30px;
`
 
export const LogoImage = styled.img`
  width: 45px;
  height: 45px;

`

export const NewsletterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: background 1s;
  &:hover {
    opacity: .6;
    animation: none;
    transition: background .3s;
    background: #FF6F00;
    box-shadow: 0px 0px 10px 3px rgb(0, 0, 0, .5);
  }
  animation: ctaNewsletter 1.5s infinite;
  @keyframes ctaNewsletter {
    0% {
      box-shadow: 0px 0px 10px 1.5px rgb(131, 3, 144, .5);
    }
    50% {
      box-shadow: 0px 0px 10px 5px rgb(131, 3, 144, .5);
    }
    100% {
      box-shadow: 0px 0px 10px 1.5px  rgb(131, 3, 144, .5);
    }
  }
`;