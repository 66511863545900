import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import MobileNavToggle from '../mobileNavToggle/MobileNavToggle'
import SocialNetworks from '../socialNetworks/SocialNetworks'

import menuStructure from '../../utils/menuStructure';
import erpLogo from '../../assests/images/logo-erp.png';

import {
  NavbarWrapper,
  NavbarBrandWrapper,
  NavbarBrand,
  NavbarBrandName,
  MenuNavBar,
  MenuNavBarList,
  MenuNavBarListItem,
  MenuWrapper,
  MobileNavToggleWrapper,
  MenuNavBarListItemWrapper,
  MenuNavButton,
  MenuNavBarListItemInfo,
  Logo
} from './Navbar.styles'

const Navbar = ({ setActive, isActive, isNavbarBackground }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const renderMenuList = useMemo(() => (
    menuStructure.map(
      ({ title, path, infoText }) => (
        <MenuNavBarListItemWrapper>
          <a href={path}>
            <MenuNavBarListItem>
              {t(title)} {t(infoText) && <MenuNavBarListItemInfo>{t(infoText)}</MenuNavBarListItemInfo>}
            </MenuNavBarListItem>
          </a>
        </MenuNavBarListItemWrapper>
      )
    )
  ), [])

  const isHome = pathname === '/'

  return (
    <NavbarWrapper isNavbarBackground={!isHome}>
      <NavbarBrandWrapper isHome={isHome}>
        <Link to='/'>
          <NavbarBrand>
            <Logo src={erpLogo} alt='Logo Empty Road Project' />
            {/* <NavbarBrandName translate='no' >Empty Road Project</NavbarBrandName> */}
          </NavbarBrand>
        </Link>
      </NavbarBrandWrapper>
       <MenuNavBar isHome={isHome}>
        <MenuNavBarList>
          {renderMenuList}
          {/* {isHome && (
            <MenuNavButton>
              <a href='#app' translate='no'>
                Hitchhikers Guide App
              </a>
            </MenuNavButton>
          )} */}
        </MenuNavBarList>
      </MenuNavBar>
      {/* <MenuWrapper>
      </MenuWrapper> */}
      
      {/* <MobileNavToggleWrapper>
        <MobileNavToggle
          isActive={isActive}
          setActive={setActive}
          dark
        />
      </MobileNavToggleWrapper> */}
    </NavbarWrapper>
  ); 
}

export default Navbar;