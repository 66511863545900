import React, {useState, useEffect} from 'react';
import PhotographyArticule from '../photographyArticule/PhotographyArticule';
import apiUrl from '../../../utils/apiUrl'
import {
  PhotographyListWrapper,
  PhotographyListContent,
  PhotographyListItem,
  SkeletonLoading
} from './PhotographyList.styles'

const PhotographyList = ({ limit, isBiggerGrid }) => {
  const [photographies, setPhotographies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(apiUrl)
    let isCurrent = true
    fetch(`${apiUrl}/api/web/blog?type=photo`)
      .then(res => res.json())
      .then(({ status, data }) => {
        if (status !== 'OK') {
          console.error('Error getting post list')
        }
        if (limit) {
          const dataSlice = data.slice(0, limit)
          isCurrent && setPhotographies(dataSlice)
        } else {
          isCurrent && setPhotographies(data)
        }
      })
      .catch(err => console.error(err));
    return ()=> isCurrent = false
  }, [])

  if (isLoading || !photographies.length) {
    return (
      <PhotographyListContent>
        <PhotographyListItem>
          <SkeletonLoading />
        </PhotographyListItem>
        <PhotographyListItem>
          <SkeletonLoading />
        </PhotographyListItem>
        <PhotographyListItem>
          <SkeletonLoading />
        </PhotographyListItem>
      </PhotographyListContent>
    )
  }

  const renderPhotographyList = () => {
    return (
      <PhotographyListContent isBiggerGrid={isBiggerGrid}>
        {
          photographies.map(adventure => (
            <PhotographyListItem key={adventure._id} isBiggerGrid={isBiggerGrid}>
              <PhotographyArticule adventure={adventure} />
            </PhotographyListItem>
          ))
        }
      </PhotographyListContent>
    )
  }

  return (
    <PhotographyListWrapper>
      {renderPhotographyList()}
    </PhotographyListWrapper>
  );
};

export default PhotographyList;