import React, { useState } from 'react';
import apiUrl from '../../utils/apiUrl'
import outlook from '../../assests/images/outlook.png'
import gmail from '../../assests/images/gmail.png'
import logoErp from '../../assests/images/logo-erp.png'
import { useTranslation, Trans } from 'react-i18next';

import {
  NewsletterBoxWrapper,
  NewsletterBoxContent,
  NewsletterBoxContentRight,
  NewsletterBoxContentLeft,
  NewsletterBoxCloseIconWrapper,
  NewsletterBoxDescription,
  NewsletterBoxInput,
  NewsletterBoxButton,
  NewsletterBoxErrorHint,
  NewsletterBoxHint,
  TextBold,
  MailIcon,
  MailsWrapper,
  MailText,
  MailsItem,
  NewsletterBoxSuccessTitle,
  NewsletterBoxSuccessText,
  NewsletterBoxSuccessInfo,
  NewsletterBoxHintEmail,
  NewsletterBoxHintName,
  NewsletterBoxHintPassword,
  Text,
  NewsletterBoxImage,
  Title,
  TextErp,
  Underline,
  NewsletterBoxArea,
  LeadMagnetText,
  NewsletterRequest,
  NewsletterRequestForm,
  Block,
  TextHighlight,
  TextPersonal,
  TextItalic,
  NewsletterBoxSuccessTextHighlight
} from './NewsletterBox.styles'

const NewsletterBox = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessMessage, setIsSuccessMessage] = useState(false)

  const { t } = useTranslation()

  const onSubmit = () => {
    const validateEmail = email => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }
    const isEmailValid = validateEmail(email)

    if (!isEmailValid) {
      setError(t('newsletter.succeed.email'))
      return
    }

    setIsLoading(true)
    
    fetch(
      `${apiUrl}/api/web/newsletter/create`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      },
    )
    .then(res => res.json())
    .then(res => {
      if (res.status === 'OK') {
        setIsSuccessMessage(true)
        setEmail(email)
      } else {
        setError(res.message)
        console.error('Error. Newsletter: ', res.message)
      }
    })
      .catch(error => {
        setError(t('newsletter.error.tryAgain'))
        console.error('Error. Newsletter: ', error)
      })
    .finally(() => {
      setIsLoading(false)
    })
  }
  

  return (

        <NewsletterBoxContent>
            {isSuccessMessage ? (
              <>
                <NewsletterBoxSuccessTitle>{t('newsletter.succeed.title')}</NewsletterBoxSuccessTitle>
                <NewsletterBoxSuccessText>
                  <Trans i18nKey="newsletter.succeed.confirmMessage" components={{ bold: <b />, email }}/>
                </NewsletterBoxSuccessText>
                <NewsletterBoxSuccessTextHighlight>
                  Es importante confirmar.
                </NewsletterBoxSuccessTextHighlight>
                <MailsWrapper>
                  <MailsItem>
                    <a href='https://mail.google.com/mail/u/0/#inbox' target='_blank' rel='noopener noreferrer'>
                      <MailIcon src={gmail} alt='gmail icon' />
                      <MailText>{t('newsletter.succeed.open')} Gmail</MailText>
                    </a>
                  </MailsItem>
                  
                  <MailsItem>
                    <a href='https://outlook.live.com/mail/0/inbox' target='_blank' rel='noopener noreferrer'>
                      <MailIcon src={outlook} alt='outlook icon' />
                      <MailText>{t('newsletter.succeed.open')} Outlook</MailText>
                    </a>
                  </MailsItem>
                </MailsWrapper>
                <NewsletterBoxSuccessInfo>{t('newsletter.succeed.checkSpam')}</NewsletterBoxSuccessInfo>
              </>
            ) : (
              <NewsletterRequest>
                <TextPersonal><TextBold><TextItalic>Estamos viajando...</TextItalic></TextBold>.</TextPersonal>
                <Block/>
                <Block/>
                <Text>Así que no esperes regularidad, del rollo un mensaje cada lunes a las 11:00.</Text>
                <Text>Solo enviamos correos cuando tenemos algo que decir o contar.</Text>
                <Block/>
                <Block/>
                <Text>Aquí no te intentaremos vender un seguro de viajes, e-Sim o VPN.</Text>
                <Block/>
                <Block/>
                <Text><TextBold>Si algún día te ofrezcemos algo será un producto de mayor valor,</TextBold></Text>                  
                <Text><TextBold>un libro cojonudo, un cuadro chulísimo con las fotos de Karo.</TextBold></Text>                  
                <Block/>
                <Block/>
                <Block/>
                <TextPersonal><TextItalic>Algo así, de valor.</TextItalic></TextPersonal>                  
                <Block/>
                <Block/>
                <Block/>
                <Text>Pero hasta entonces puedes dejar tu email aquí abajo,</Text>
                <Text><TextItalic>si te apetece.</TextItalic></Text>
                <NewsletterRequestForm>
                  <NewsletterBoxInput placeholder='Tu Email' value={email} onChange={(evt) => {setEmail(evt.target.value)}} />
                  <NewsletterBoxHintEmail>* He leído y acepto la Política de Privacidad.</NewsletterBoxHintEmail>
                </NewsletterRequestForm>
                {error && <NewsletterBoxErrorHint>{error}</NewsletterBoxErrorHint>}
                <NewsletterBoxButton onClick={onSubmit}>{isLoading ? t('newsletter.buttonTextSending') : 'Dejar mi Email'}</NewsletterBoxButton>
              </NewsletterRequest>
            )}
        </NewsletterBoxContent>
    
  ); 
}

export default NewsletterBox;