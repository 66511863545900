import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const NewsletterBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #E6DECB;
  padding: 50px 80px 70px;
`

export const NewsletterRequest = styled.div`
    // text-align: left
`

export const NewsletterBoxDescription = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
  color: ${greyDark};
  padding: 30px 80px 40px;
  border: 1px solid red;

`

export const NewsletterBoxInput = styled.input`
  font-size: 16px;
  width: 80%;
  box-sizing: border-box;
  padding: 15px 20px;

`
export const NewsletterBoxHintPassword = styled.p`
  align-self: flex-start;
  font-weight: 300;
  margin-top: 5px;
  font-size: 11px;
  text-align: left;
  `

  export const NewsletterBoxHintEmail = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 11px;
  text-align: left;
  margin-left: 120px;
`
  export const NewsletterBoxHintName = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 11px;
  text-align: left;
`

export const NewsletterBoxErrorHint = styled.p`
  margin-top: 5px;
  font-size: 12px;
  color: red;
`

export const NewsletterBoxButton = styled.button`
padding: 15px 20px;
color: #fff;
width: max-content;
background: #FF6F00;
border-radius: 3px;
cursor: pointer;
transition: .2s;
letter-spacing: .8px;
border: none;
font-size: 16px;
font-weight: bold;
width: 80%;
&:hover {
  background: black;
}

`

export const MailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 50px 0;
  @media(max-width: 720px) {
    margin: 30px 0;
  }
`
export const MailsItem = styled.div`
   display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
`

export const MailIcon = styled.img`
  width: 35px;
  margin-right: 8px;
  @media(max-width: 720px) {
    width: 25px;
  }
`

export const MailText = styled.span`
  font-size: 16px;
  color:  #FF6F00;
  text-decoration: underline;
  white-space: nowrap;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`

export const NewsletterBoxSuccessTitle = styled.p`
  font-size: 32px;
  font-family: 'Allerta Stencil', sans-serif;
  margin-bottom: 30px;
  @media(max-width: 720px) {
    font-size: 24px;
  }
`
export const NewsletterBoxSuccessText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`

export const NewsletterBoxSuccessTextHighlight = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: red;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`
export const NewsletterBoxSuccessInfo = styled.p`
  font-size: 14px;
  font-weight: 500;
@media(max-width: 720px) {
    font-size: 12px;
  }
`
export const LeadMagnetText = styled.span`
  font-size: 14px;
  display: block;
  margin: 30px auto 30px;
  line-height: 20px;
  max-width: 380px;
  font-weight: 600
`

export const Title = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
`

export const TextErp = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: bold;
  color: #FF6F00
`

export const Underline = styled.span`
  font-weight: bold;
  font-weight: 800;
`

export const Text = styled.p`
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 100;
  color: ${({ color }) => (color ? color : '#000')};
`;

export const TextLight = styled.p`
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 100;
  color: #fff;
  color: ${({ color }) => (color ? color : '#000')};
`;

export const TextPersonal = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 1.1rem;
  line-height: 24px;
  color: ${({ color }) => (color ? color : '#000')};
`;

export const TextBold = styled.span`
  font-weight: bold;
`;

export const TextHighlight = styled.span`
font-weight: bold;
  color: #FF6F00
`;

export const TextItalic = styled.span`
  font-style: italic
`;

export const NewsletterRequestForm = styled.form`
  margin-top: 30px
`;

export const Block = styled.div`
  height: 5px
`;